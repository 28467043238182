/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// The CFOStartup Material main context
const TargetUserContext = createContext();

// Setting custom name for the context which is visible on react dev tools
TargetUserContext.displayName = 'TargetUserContext';

// React reducer
function reducer(state, action) {
  // eslint-disable-next-line no-console
  console.log('Context: TargetUser - Action: ', action);
  switch (action.type) {
    case 'TARGET_USER': {
      return { ...state, targetUser: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// React context provider
function TargetUserControllerProvider({ children }) {
  const initialState = {
    targetUser: null,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  return (
    <TargetUserContext.Provider value={[controller, dispatch]}>
      {children}
    </TargetUserContext.Provider>
  );
}

// React custom hook for using context
function useTargetUserController() {
  const context = useContext(TargetUserContext);

  if (!context) {
    throw new Error(
      'useTargetUserController should be used inside the TargetUserControllerProvider.'
    );
  }

  return context;
}

// Typechecking props for the UIControllerProvider
TargetUserControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setTargetUser = (dispatch, value) => dispatch({ type: 'TARGET_USER', value });

export { TargetUserControllerProvider, useTargetUserController, setTargetUser };
