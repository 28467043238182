import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { getProvider } from 'utility/web3/provider';
import { ENVIRONMENT, WALLET_CONNECT_PROJECT_ID } from 'config/appConfig';

import Spinner from 'DS/components/DsLoader/FullScreenSpinner';

// Soft UI Context Provider

import { TargetUserControllerProvider } from 'contexts/targetUser';

import { AuthProvider } from 'contexts/auth/auth.provider';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Web3OnboardProvider, init } from '@web3-onboard/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import injectedModule from '@web3-onboard/injected-wallets';
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import ledgerModule from '@web3-onboard/ledger';

const goerliChain = {
  id: 5,
  token: 'ETH',
  label: 'Goerli',
  rpcUrl: `https://goerli.blockpi.network/v1/rpc/public`,
};

const sepoliaChain = {
  id: 11155111,
  token: 'ETH',
  label: 'Sepolia',
  rpcUrl: `https://sepolia.infura.io/v3/`,
};

const polygonChain = {
  id: 137,
  token: 'MATIC',
  label: 'Polygon',
  rpcUrl: `https://polygon-rpc.com/`,
};

let envChain = polygonChain;
let requiredChains = [137];

if (ENVIRONMENT !== 'prod') {
  envChain = sepoliaChain;
  requiredChains = [11155111];
}

const ledger = ledgerModule({
  projectId: WALLET_CONNECT_PROJECT_ID,
  // requiredChains: [137, 5],
  requiredChains,
  // chainId: 1,
  // rpc: {
  //   1: `https://cloudflare-eth.com/`, // Mainnet
  //   5: 'https://goerli.optimism.io', // Goerli
  //   137: 'https://polygon-rpc.com/', // Polygon
  // },
});

// const chains = [goerliChain, polygonChain];
const chains = [envChain];
const wallets = [injectedModule(), ledger];

const web3Onboard = init({
  wallets,
  chains,
  appMetadata: {
    name: 'Aconcagua finance',
    icon: '<svg>App Icon</svg>',
    description: 'Aconcagua finance',
  },
});

Date.prototype.yyyymmdd = function () {
  const mm = this.getMonth() + 1; // getMonth() is zero-based
  const dd = this.getDate();

  return [this.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-');
};

if (ENVIRONMENT === 'local') {
  setInterval(() => {
    const iframes = document.querySelectorAll('iframe');
    for (let i = 0; i < iframes.length; i++) {
      const iframe = iframes[i];

      if (iframe && (!iframe.id || iframe.id === '') && !iframe.src.includes('typeform'))
        iframes[i].parentNode.removeChild(iframes[i]);
    }
  }, 3000);
}

// ** Lazy load app
const LazyApp = lazy(() => import('./App'));

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Suspense fallback={<Spinner />}>
      <TargetUserControllerProvider>
        <AuthProvider>
          <Web3OnboardProvider web3Onboard={web3Onboard}>
            {/* <Web3ReactProvider getLibrary={getProvider}> */}
            <LazyApp />
            {/* </Web3ReactProvider> */}
          </Web3OnboardProvider>
        </AuthProvider>
      </TargetUserControllerProvider>
    </Suspense>
  </BrowserRouter>
);
